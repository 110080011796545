import React from 'react';
import { ModuleCardTitleUI } from '../../../../components/common';
import { Grid } from '@mui/material';
import { BackgroundBox } from '../../../../components/common/BackgroundBox/styles';

export const AccountState = ({ customer_status }) => {
  const obtener_label_customer_status = (customer_status) => {
    let customer_status_label = '';

    switch (customer_status) {
      case -1:
        customer_status_label =
          'Tu cuenta de Emprepack aún no está activa, debes enviar toda la documentación para proceder. El proceso de evaluación puede demorar hasta 1 semana, al aceptar la cuenta comenzará a funcionar automáticamente en tu tienda.';
        break;
      case 0:
        customer_status_label =
          'Su cuenta de Emprepack se encuentra en proceso de verificación de documentación. El proceso de evaluación puede demorar hasta 1 semana, al aceptar la cuenta comenzará a funcionar automáticamente en tu tienda.';
        break;
      case 1:
        customer_status_label = 'Su cuenta de Emprepack se encuentra activa.';
        break;
      case 2:
        customer_status_label =
          'Su cuenta se encuentra con deuda, debes abonarla desde Acciones -> Abonar deuda.';
        break;
      case 3:
        customer_status_label =
          'Su cuenta se encuentra bloqueada. Para mayor información, contactese por email a hola@emprepack.com';
        break;
      case 4:
        customer_status_label =
          'Los archivos de verificación enviados son invalidos, debes volver a enviarlos.';
        break;
      default:
        customer_status_label = '';
        break;
    }
    return customer_status_label;
  };

  return (
    <BackgroundBox>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleCardTitleUI
            title="Estado de cuenta"
            subtitles={[obtener_label_customer_status(customer_status)]}
          />
        </Grid>
      </Grid>
    </BackgroundBox>
  );
};
