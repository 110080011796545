import React from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Box,
  Button,
} from '@mui/material';

import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Validate } from '../../../../../other';
import { ToggleUI } from '../../../../../components/common';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import {
  createAmplitudeEventWithDevice,
  sales_amplitude_events,
} from '../../../../../components/amplitude';

const OrdenOverviewPayment = ({ theme, sale, help }) => {
  return (
    <Card sx={{ ...theme.card.card, height: '100%' }}>
      <CardHeader
        title={`Pago: ${Validate.trim_uppercase(
          Validate.label_metodo_pago(sale.v_metodo_pago)
        )}`}
        subheader={
          sale.v_metodo_pago === 1 &&
          sale.v_estado_pago !== -1 &&
          sale.v_codigo_pago_adicional
            ? 'Operación: #' + sale.v_codigo_pago_adicional
            : ''
        }
        avatar={
          <Avatar
            sx={{ width: '55px', height: '55px' }}
            src={Validate.logo_metodo_pago(sale.v_metodo_pago)}
          />
        }
        sx={{
          paddingTop: '15px',
          paddingBottom: '15px',
          '& span': {
            fontWeight: '500',
            fontSize: '1rem',
          },
        }}
      />
      <Divider />
      <CardContent
        sx={{
          padding: '16px !important',
          [theme.breakpoints.up('md')]: {
            minHeight: '110px',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            margin: '1px 0px',
            fontWeight: '500',
            color: 'rgba(0,0,0,0.87)',
          }}
        >
          Estado del pago: {help.estado_pago.estado}
        </Typography>
        <ToggleUI show={help.estado_pago.ayuda ? true : false}>
          <Typography
            sx={{
              fontSize: '0.87rem',
              margin: '10px 0px 1px 0px',
              fontWeight: '400',
              color: 'rgba(0,0,0,0.87)',
            }}
          >
            {help.estado_pago.ayuda}.
            <ToggleUI show={sale.v_estado_pago === 5}>
              {sale.v_stock_devuelto
                ? ' Los productos de la orden fueron devueltos nuevamente al stock'
                : '. Los productos de la orden aún no fueron devueltos al stock, puedes hacerlo desde el menú "ACCIONES"'}
            </ToggleUI>
          </Typography>
        </ToggleUI>
      </CardContent>
    </Card>
  );
};

const OrdenOverviewShipment = ({ theme, sale, help }) => {
  let envio_seleccionado = JSON.parse(sale.v_arr_envio_seleccionado_json);

  return (
    <Card sx={{ ...theme.card.card, height: '100%' }}>
      <CardHeader
        title={`Envío: ${Validate.trim_uppercase(
          Validate.label_metodo_envio(sale.v_metodo_envio, envio_seleccionado)
        )}`}
        avatar={
          <Avatar
            sx={{ width: '55px', height: '55px' }}
            src={Validate.logo_metodo_envio(sale.v_metodo_envio)}
          />
        }
        sx={{
          paddingTop: '15px',
          paddingBottom: '15px',
          '& span': {
            fontWeight: '500',
            fontSize: '1rem',
          },
        }}
      />
      <Divider />
      <CardContent
        sx={{
          padding: '16px !important',
          [theme.breakpoints.up('md')]: {
            minHeight: '110px',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '1rem',
            margin: '1px 0px',
            fontWeight: '500',
            color: 'rgba(0,0,0,0.87)',
          }}
        >
          Estado del envío:{' '}
          {Validate.trim_uppercase(
            Validate.label_estado_envio(
              sale.v_metodo_envio,
              sale.v_estado_envio
            )
          )}
        </Typography>
        <ToggleUI show={help.metodo_envio.ayuda ? true : false}>
          <Typography
            sx={{
              fontSize: '0.87rem',
              margin: '10px 0px 1px 0px',
              fontWeight: '400',
              color: 'rgba(0,0,0,0.87)',
            }}
          >
            {help.metodo_envio.ayuda}.
          </Typography>
        </ToggleUI>
      </CardContent>
    </Card>
  );
};

const OrdenOverviewObservation = ({ theme, sale }) => {
  return (
    <Card sx={{ ...theme.card.card }}>
      <CardContent sx={{ padding: '16px !important' }}>
        <Typography
          sx={{
            fontSize: '1.1rem',
            fontWeight: '500',
            margin: '1px 0px',
            color: 'rgba(0,0,0,0.87)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ChatOutlinedIcon
            sx={{ marginRight: '5px', color: 'rgba(0,0,0,0.87)' }}
          />{' '}
          Observaciones
        </Typography>
        <Typography
          sx={{
            fontSize: '0.87rem',
            margin: '10px 0px 1px 0px',
            fontWeight: '400',
            color: 'rgba(0,0,0,0.87)',
          }}
        >
          {sale.v_observacion}
        </Typography>
      </CardContent>
    </Card>
  );
};

const OrdenOverviewPurchaseTracking = ({
  theme,
  linkStore,
  handleShowAlert,
}) => {
  return (
    <Card sx={{ ...theme.card.card }}>
      <CardContent sx={{ padding: '16px !important' }}>
        <Typography
          sx={{
            fontSize: '1.1rem',
            fontWeight: '500',
            margin: '1px 0px',
            color: 'rgba(0,0,0,0.87)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Seguimiento de compra
        </Typography>
        <Typography
          sx={{
            fontSize: '0.87rem',
            margin: '10px 0px 1px 0px',
            fontWeight: '400',
            color: 'rgba(0,0,0,0.87)',
          }}
        >
          Compartí esta página para que tu cliente pueda ver el progreso de su
          compra.
        </Typography>
        <Box
          sx={{
            display: 'grid',
            columnGap: '8px',
            gridTemplateColumns: 'repeat(5,1fr)',
          }}
          mt={2}
        >
          <Button
            sx={{ gridColumn: '1 / 2', minWidth: '146px' }}
            variant="contained"
            startIcon={<ContentCopyIcon />}
            onClick={() => {
              handleShowAlert();
            }}
          >
            Copiar link
          </Button>
          <Button
            sx={{ gridColumn: '2 / 3', minWidth: '146px' }}
            variant="outlined"
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              window.open(linkStore, '_blank');
            }}
          >
            Acceder
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const OrdenOverview_ = ({ sale, help, updateAlert }) => {
  const domainStore = useSelector((state) => state.dominio.d_nombre);

  const theme = useTheme();

  const linkStore = `https://${domainStore}/order?order_status=none&order_method=${sale.v_metodo_pago}&order_code=${sale.v_checkout}&order_id=${sale.idVentas}`;

  const handleShowAlert = () => {
    navigator.clipboard.writeText(linkStore);
    updateAlert({
      open: true,
      type: 'success',
      message: 'El link de seguimiento ha sido copiado ',
    });

    createAmplitudeEventWithDevice(
      sales_amplitude_events.ventas_copiar_link.event_name
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <OrdenOverviewPayment theme={theme} sale={sale} help={help} />
      </Grid>
      <Grid item sm={6} xs={12}>
        <OrdenOverviewShipment theme={theme} sale={sale} help={help} />
      </Grid>
      <ToggleUI show={sale.v_observacion ? true : false}>
        <Grid item xs={12}>
          <OrdenOverviewObservation theme={theme} sale={sale} />
        </Grid>
      </ToggleUI>
      <Grid item xs={12}>
        <OrdenOverviewPurchaseTracking
          theme={theme}
          linkStore={linkStore}
          handleShowAlert={handleShowAlert}
        />
      </Grid>
    </Grid>
  );
};

export const OrdenOverview = OrdenOverview_;
