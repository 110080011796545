import { styled } from '@mui/material/styles';
import { Card, Grid } from '@mui/material';

export const BannerContainer = styled(Grid)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  boxShadow: '0px 8px 32px 0px #31364333',
  [theme.breakpoints.up('md')]: {
    left: '300px',
    width: 'calc(100% - 300px)',
    borderLeft: '1px solid #f5f6f8',
  },
}));

export const BannerContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 34px 16px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '14px 0px',
    '&:last-child': {
      padding: '14px 0px',
    },
  },
}));

export const Container = styled(Card)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  color: '#3A3A3A',
});
