import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  metodosenvioSolicitarEmprepack,
  metodosenvioEditarDireccionEmprepack,
  metodosenvioEditarContactoEmprepack,
  metodoenvioConfigurarEmprepack,
} from '../../../store/actions';
import { Config, Regex, RegexExtra, Validate } from '../../../other';
import { Grid, Box } from '@mui/material';
import {
  AlertUI,
  ModuleTitleUI,
  ToggleUI,
  CardModuleUI,
} from '../../../components/common';
import {
  method_shipment_amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';
import * as braze from '@braze/web-sdk';
import { WrapperLayout } from '../../../layouts/WrapperLayout';
import ButtonMui from '../../../components/common/ButtonMui';
import { BillDataEmprepack } from './subcomponents/BillData';
import { FreeShipmentData } from './subcomponents/FreeShipmentData';
import { ShipmentWithChargeData } from './subcomponents/ShipmentWithChargeData';
import { ShipmentContactData } from './subcomponents/ShipmentContactData';
import { ShipmentAddressData } from './subcomponents/ShipmentAddressData';
import { AccountState } from './subcomponents/AccountState';
import { ConfigurationMenu } from './subcomponents/ConfigurationMenu';
import { ModalAddressData } from './subcomponents/ModalAddressData';
import { ModalContactData } from './subcomponents/ModalContactData';
import { ModalTyC } from './subcomponents/ModalTyC';
import TyCSection from './subcomponents/TyCSection';
import useGetShipmentMethods from '../../../hooks/useGetShipmentMethods';
import useGetBillingData from '../../../hooks/useGetBillingData';
import useGetEmprepackData from '../../../hooks/useGetEmprepackData';
import useGetSucursalesEmprepack from '../../../hooks/useGetSucursalesEmprepack';

const Emprepack_ = ({
  metodosenvioSolicitarEmprepack,
  metodosenvioEditarDireccionEmprepack,
  metodosenvioEditarContactoEmprepack,
  metodoenvioConfigurarEmprepack,
}) => {
  const [state, setState] = useState({
    configuraciones: {
      me_emprepack: {
        validate: false,
        name: 'me_emprepack',
        label: 'Activar Emprepack',
        value: 1,
        change_param: 'configuraciones',
      },
      me_emprepack_tipo: {
        validate: false,
        required: true,
        error: false,
        label: 'Ofrecer envío gratis',
        id: 'me_emprepack_tipo',
        name: 'me_emprepack_tipo',
        change_param: 'configuraciones',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
        },
      },
      me_emprepack_gratis_minimo: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto mínimo',
        id: 'me_emprepack_gratis_minimo',
        name: 'me_emprepack_gratis_minimo',
        change_param: 'configuraciones',
        regex: Regex.PRECIO.ARG,
        value: '',
        default_value: '',
        placeholder: '3000',
        messages: {
          help: 'El monto mínimo requerido de la compra para poder acceder al envío gratis',
          error: 'Por favor, ingrese un monto mínimo válido',
          error_extra: RegexExtra.PRECIO.ARG,
        },
      },
      me_emprepack_recargo_monto: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Monto fijo a recargar/descontar',
        id: 'me_emprepack_recargo_monto',
        name: 'me_emprepack_recargo_monto',
        change_param: 'configuraciones',
        regex: Regex.PRECIO_CON_SIGNO.ARG,
        value: '',
        default_value: '',
        placeholder: '50.00',
        messages: {
          error: 'Por favor, ingrese un monto válido',
          error_extra: RegexExtra.PRECIO_CON_SIGNO.ARG,
        },
      },
      me_emprepack_recargo_porcentaje: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'number',
        label: 'Porcentaje sobre la tarifa a recargar/descontar',
        id: 'me_emprepack_recargo_porcentaje',
        name: 'me_emprepack_recargo_porcentaje',
        change_param: 'configuraciones',
        regex: Regex.PORCENTAJE_CON_SIGNO,
        value: '',
        default_value: '',
        placeholder: '5',
        messages: {
          error: 'Por favor, ingrese un porcentaje válido',
          error_extra: RegexExtra.PORCENTAJE_CON_SIGNO,
        },
      },
      me_emprepack_office_id: {
        validate: false,
        required: true,
        error: false,
        label: 'Sucursal de despacho',
        id: 'me_emprepack_office_id',
        name: 'me_emprepack_office_id',
        change_param: 'configuraciones',
        value: 0,
        messages: {
          error: 'Por favor, seleccione una opción',
          help: 'Aquí podrás elegir la sucursal Andreani donde despacharás los paquetes.',
        },
      },
      me_emprepack_gratis_sucursal: {
        validate: false,
        name: 'me_emprepack_gratis_sucursal',
        label: 'Ofrecer envío gratis para envíos a sucursal',
        value: 0,
        change_param: 'configuraciones',
      },
      me_emprepack_gratis_domicilio: {
        validate: false,
        name: 'me_emprepack_gratis_domicilio',
        label: 'Ofrecer envío gratis para envíos a domicilio',
        value: 0,
        change_param: 'configuraciones',
      },
    },
    emprepack: {
      customer_email: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Email',
        id: 'customer_email',
        name: 'customer_email',
        change_param: 'emprepack',
        regex: Regex.EMAIL,
        value: '',
        default_value: '',
        placeholder: 'Email',
        messages: {
          help: '',
          error: 'Por favor, ingrese un email válido',
          error_extra: RegexExtra.EMAIL,
        },
      },
      customer_name: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Nombre del responsable',
        id: 'customer_name',
        name: 'customer_name',
        change_param: 'emprepack',
        regex: Regex.NOMBRE_2_45,
        value: '',
        default_value: '',
        placeholder: 'Nombre del responsable',
        messages: {
          error: 'Por favor, ingrese un nombre válido',
          help: 'Debe coincidir con el nombre del DNI adjunto',
          error_extra: RegexExtra.NOMBRE_2_45,
        },
      },
      customer_surname: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Apellido del responsable',
        id: 'customer_surname',
        name: 'customer_surname',
        change_param: 'emprepack',
        regex: Regex.NOMBRE_2_45,
        value: '',
        default_value: '',
        placeholder: 'Apellido del responsable',
        messages: {
          error: 'Por favor, ingrese un apellido válido',
          help: 'Debe coincidir con el apellido del DNI adjunto',
          error_extra: RegexExtra.NOMBRE_2_45,
        },
      },
      customer_identification: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'DNI del responsable',
        id: 'customer_identification',
        name: 'customer_identification',
        change_param: 'emprepack',
        regex: Regex.DNI,
        value: '',
        default_value: '',
        placeholder: 'DNI del responsable',
        messages: {
          error: 'Por favor, ingrese un DNI válido',
          help: 'Debe coincidir con el número de documento del DNI adjunto',
          error_extra: RegexExtra.DNI,
        },
      },
      customer_phone: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Teléfono del responsable',
        id: 'customer_phone',
        name: 'customer_phone',
        change_param: 'emprepack',
        regex: Regex.TELEFONO,
        value: '',
        default_value: '',
        placeholder: 'Teléfono del responsable',
        messages: {
          error: 'Por favor, ingrese un teléfono válido',
          help: 'Será utilizado para medio de contacto para los envíos',
          error_extra: RegexExtra.TELEFONO,
        },
      },
      customer_street: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Calle',
        id: 'customer_street',
        name: 'customer_street',
        change_param: 'emprepack',
        regex: Regex.CALLE_1_30,
        value: '',
        default_value: '',
        placeholder: 'Av. Pellegrini',
        messages: {
          help: '',
          error: 'Por favor, ingrese una calle válida',
          error_extra: RegexExtra.CALLE_1_30,
        },
      },
      customer_street_number: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Número',
        id: 'customer_street_number',
        name: 'customer_street_number',
        change_param: 'emprepack',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: '13210',
        messages: {
          help: '',
          error: 'Por favor, ingrese un número de calle válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      customer_floor: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Piso',
        id: 'customer_floor',
        name: 'customer_floor',
        change_param: 'emprepack',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: '10',
        messages: {
          help: '',
          error: 'Por favor, ingrese un número de piso válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      customer_apartment: {
        auth: true,
        validate: true,
        required: false,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Dpto',
        id: 'customer_apartment',
        name: 'customer_apartment',
        change_param: 'emprepack',
        regex: Regex.ALTURA_CALLE_1_10,
        value: '',
        default_value: '',
        placeholder: 'A',
        messages: {
          help: '',
          error: 'Por favor, ingrese un número de dpto válido',
          error_extra: RegexExtra.ALTURA_CALLE_1_10,
        },
      },
      customer_city: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Ciudad',
        id: 'customer_city',
        name: 'customer_city',
        change_param: 'emprepack',
        regex: Regex.CIUDAD_2_45,
        value: '',
        default_value: '',
        placeholder: 'Rosario',
        messages: {
          help: '',
          error: 'Por favor, ingrese una ciudad válida',
          error_extra: RegexExtra.CIUDAD_2_45,
        },
      },
      customer_state: {
        validate: false,
        required: true,
        error: false,
        label: 'Provincia',
        id: 'customer_state',
        name: 'customer_state',
        change_param: 'emprepack',
        value: 'Buenos Aires',
        messages: {
          help: '',
          error: 'Por favor, seleccione una opción',
        },
      },
      customer_zipcode: {
        auth: false,
        validate: true,
        required: true,
        disabled: false,
        error: false,
        type: 'text',
        label: 'Código postal',
        id: 'customer_zipcode',
        name: 'customer_zipcode',
        change_param: 'emprepack',
        regex: Regex.CODIGO_POSTAL,
        value: '',
        default_value: '',
        placeholder: '1234',
        messages: {
          help: '',
          error: 'Por favor, ngrese un número de código postal válido',
          error_extra: RegexExtra.CODIGO_POSTAL,
        },
      },
      customer_status: {
        validate: false,
        value: -2,
      },
    },
    kyc_dni: {
      dni_frontal: {
        validate: true,
        auth: false,
        required: true,
        id: 'dni_frontal',
        name: 'dni_frontal',
        change_param: 'kyc_dni',
        file: null,
        messages: {
          help: 'Sube una foto de la parte frontal de tu DNI',
          error: 'Por favor, sube un archivo válido',
        },
      },
      dni_reverso: {
        validate: true,
        auth: false,
        required: true,
        id: 'dni_reverso',
        name: 'dni_reverso',
        change_param: 'kyc_dni',
        file: null,
        messages: {
          help: 'Sube una foto de la parte trasera de tu DNI',
          error: 'Por favor, sube un archivo válido',
        },
      },
      dni_selfie: {
        validate: true,
        auth: false,
        required: true,
        id: 'dni_selfie',
        name: 'dni_selfie',
        change_param: 'kyc_dni',
        file: null,
        messages: {
          help: 'Sube una selfie donde se vea tu rostro y la parte frontal de tu DNI',
          error: 'Por favor, sube un archivo válido',
        },
      },
    },
    kyc_domicilio: {
      comprobante_domicilio: {
        validate: true,
        auth: false,
        required: true,
        id: 'comprobante_domicilio',
        name: 'comprobante_domicilio',
        change_param: 'kyc_domicilio',
        file: null,
        messages: {
          help: 'Sube un documento que compruebe tu domicilio y esté a tu nombre, puede ser una factura o similar',
          error: 'Por favor, sube un archivo válido',
        },
      },
    },
    facturacion_activa: -1,
    loading_emprepack: true,
    loading_error: false,
    forms: {
      configurar_solicitar_emprepack: false,
      editar_domicilio: false,
      editar_contacto: false,
    },
    modals: {
      editar_domicilio: false,
      editar_contacto: false,
      tos: false,
    },
  });
  const [alert, setAlert] = useState({
    open: false,
    type: 'error',
    message: '',
  });

  const { data: shipmentMethodsData, isLoading: isShipmentMethodsLoading } =
    useGetShipmentMethods();

  const {
    data: billingData,
    isError: isBillingDataError,
    isLoading: isBillingDataLoading,
  } = useGetBillingData();

  const {
    data: emprepackData,
    isError: isEmprepackDataError,
    isLoading: isEmprepackDataLoading,
  } = useGetEmprepackData();
  useGetSucursalesEmprepack();

  const idCountry =
    useSelector((state) => state.tienda.Country_idCountry) || 'ARG';

  useEffect(() => {
    if (!isShipmentMethodsLoading) {
      Validate.cargar_formulario_sin_nulos(
        shipmentMethodsData,
        state.configuraciones,
        (configuraciones_obj) => {
          setState((prevState) => ({
            ...prevState,
            configuraciones: configuraciones_obj,
          }));
        }
      );
    }
  }, [isShipmentMethodsLoading]);

  useEffect(() => {
    if (!isBillingDataLoading) {
      setState((prevState) => ({
        ...prevState,
        facturacion_activa: billingData.df_facturacion_activada,
      }));
    }
  }, [isBillingDataLoading]);

  useEffect(() => {
    if (!isEmprepackDataLoading) {
      Validate.cargar_formulario_sin_nulos(
        emprepackData,
        state.emprepack,
        (emprepack_obj) => {
          setState((prevState) => ({
            ...prevState,
            emprepack: emprepack_obj,
          }));
        }
      );
    }
  }, [isEmprepackDataLoading]);

  const handleChange = (e, blur, aditional) => {
    const value = e.target.value;
    const name = e.target.name;
    const stateAux = {
      [aditional]: {
        ...state[aditional],
        [name]: {
          ...state[aditional][name],
          value: value,
        },
      },
    };

    Validate.validate_input(stateAux[aditional][name], blur, (input) => {
      setState((prevState) => ({
        ...prevState,
        [aditional]: {
          ...prevState[aditional],
          [name]: input,
        },
      }));
    });
  };

  const handleChangeZipCode = (e, blur, aditional) => {
    let value = e.target.value;
    let name = e.target.name;

    const stateAux = {
      [aditional]: {
        ...state[aditional],
        [name]: {
          ...state[aditional][name],
          value: value,
        },
      },
    };

    Validate.validate_input(stateAux[aditional][name], blur, (input) => {
      setState((prevState) => ({
        ...prevState,
        configuraciones: {
          ...prevState.configuraciones,
          me_emprepack_office_id: {
            ...prevState.configuraciones.me_emprepack_office_id,
            value: blur
              ? prevState.configuraciones.me_emprepack_office_id.value
              : 0,
          },
        },
        [aditional]: {
          ...prevState[aditional],
          [name]: input,
        },
      }));
    });
  };

  const handleChangeSelect = (e, aditional) => {
    let name = e.target.name;
    let value =
      name === 'customer_state'
        ? Validate.trim(e.target.value)
        : Validate.trim_int(e.target.value);

    setState((prevState) => ({
      ...prevState,
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: value,
        },
      },
    }));
  };

  const handleChangeCheckbox = (e, aditional) => {
    let name = e.target.name;

    setState((prevState) => ({
      ...prevState,
      [aditional]: {
        ...prevState[aditional],
        [name]: {
          ...prevState[aditional][name],
          value: prevState[aditional][name]['value'] ? 0 : 1,
        },
      },
    }));
  };

  const handleChangeDoc = (_err, data) => {
    let { change_param, name } = data;

    setState((prevState) => ({
      ...prevState,
      [change_param]: {
        ...prevState[change_param],
        [name]: { ...prevState[change_param][name], ...data },
      },
    }));
  };

  const handleCloseAlert = () => {
    setAlert((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  //abre y cierra los modales
  const modalHandler = (open, id) => {
    setState((prevState) => ({
      ...prevState,
      modals: {
        ...prevState.modals,
        [id]: open,
      },
    }));
  };

  const handleSubmitSolicitarEmprepack = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        configurar_solicitar_emprepack: true,
      },
    }));

    let { configuraciones, emprepack, kyc_dni, kyc_domicilio } = state;

    if (
      Validate.validar_formulario(configuraciones) &&
      Validate.validar_formulario(emprepack)
    ) {
      if (state.configuraciones.me_emprepack_office_id.value > 0) {
        if (Validate.validar_formulario(kyc_domicilio)) {
          if (Validate.validar_formulario(kyc_dni)) {
            metodosenvioSolicitarEmprepack(
              {
                customer_email: emprepack.customer_email.value,
                customer_name: emprepack.customer_name.value,
                customer_surname: emprepack.customer_surname.value,
                customer_identification:
                  emprepack.customer_identification.value,
                customer_phone: emprepack.customer_phone.value,
                customer_street: emprepack.customer_street.value,
                customer_street_number: emprepack.customer_street_number.value,
                customer_floor: emprepack.customer_floor.value,
                customer_apartment: emprepack.customer_apartment.value,
                customer_city: emprepack.customer_city.value,
                customer_state: emprepack.customer_state.value,
                customer_zipcode: emprepack.customer_zipcode.value,
                me_emprepack: configuraciones.me_emprepack.value,
                me_emprepack_tipo: configuraciones.me_emprepack_tipo.value,
                me_emprepack_gratis_minimo:
                  configuraciones.me_emprepack_gratis_minimo.value,
                me_emprepack_recargo_monto:
                  configuraciones.me_emprepack_recargo_monto.value,
                me_emprepack_recargo_porcentaje:
                  configuraciones.me_emprepack_recargo_porcentaje.value,
                me_emprepack_office_id:
                  configuraciones.me_emprepack_office_id.value,
                me_emprepack_gratis_sucursal:
                  configuraciones.me_emprepack_gratis_sucursal.value,
                me_emprepack_gratis_domicilio:
                  configuraciones.me_emprepack_gratis_domicilio.value,
                dni_frontal: kyc_dni.dni_frontal.file,
                dni_reverso: kyc_dni.dni_reverso.file,
                dni_selfie: kyc_dni.dni_selfie.file,
                comprobante_domicilio: kyc_domicilio.comprobante_domicilio.file,
              },
              (err, resp) => {
                setState((prevState) => ({
                  ...prevState,
                  forms: {
                    ...prevState.forms,
                    configurar_solicitar_emprepack: false,
                  },
                  emprepack: {
                    ...prevState.emprepack,
                    customer_status: {
                      ...prevState.emprepack.customer_status,
                      validate: false,
                      value: err ? -1 : 0,
                    },
                  },
                }));

                setAlert((prevState) => ({
                  ...prevState,
                  open: true,
                  message: err
                    ? resp.message
                    : 'La activación de Emprepack ha sido solicitada con éxito. Este proceso puede demorar hasta 1 semana.',
                  type: err ? 'error' : 'success',
                }));
              }
            );
          } else {
            setState((prevState) => ({
              ...prevState,
              forms: {
                ...prevState.forms,
                configurar_solicitar_emprepack: false,
              },
            }));

            setAlert((prevState) => ({
              ...prevState,
              open: true,
              message: 'Sube las fotos de tu DNI, del frente, reverso y selfie',
              type: 'error',
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            forms: {
              ...prevState.forms,
              configurar_solicitar_emprepack: false,
            },
          }));

          setAlert((prevState) => ({
            ...prevState,
            open: true,
            message:
              'Sube un documento que compruebe tu domicilio y este a tu nombre para verificar la dirección',
            type: 'error',
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            configurar_solicitar_emprepack: false,
          },
        }));

        setAlert((prevState) => ({
          ...prevState,
          open: true,
          message: 'Debes seleccionar una sucursal de entrega',
          type: 'error',
        }));
      }
    } else {
      Validate.validar_formulario_actualizar_obj(
        configuraciones,
        (new_configuraciones) => {
          Validate.validar_formulario_actualizar_obj(
            emprepack,
            (new_emprepack) => {
              setState((prevState) => ({
                ...prevState,
                forms: {
                  ...prevState.forms,
                  configurar_solicitar_emprepack: false,
                },
                emprepack: new_emprepack,
                configuraciones: new_configuraciones,
              }));

              setAlert((prevState) => ({
                ...prevState,
                open: true,
                message: 'Por favor, revisá tus datos, tenés algunos errores',
                type: 'error',
              }));
            }
          );
        }
      );
    }
  };

  const handleSubmitConfigurarEmprepack = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        configurar_solicitar_emprepack: true,
      },
    }));

    let configuraciones = state.configuraciones;

    if (Validate.validar_formulario(configuraciones)) {
      metodoenvioConfigurarEmprepack(
        {
          me_emprepack: configuraciones.me_emprepack.value,
          me_emprepack_tipo: configuraciones.me_emprepack_tipo.value,
          me_emprepack_gratis_minimo:
            configuraciones.me_emprepack_gratis_minimo.value,
          me_emprepack_recargo_monto:
            configuraciones.me_emprepack_recargo_monto.value,
          me_emprepack_recargo_porcentaje:
            configuraciones.me_emprepack_recargo_porcentaje.value,
          me_emprepack_gratis_sucursal:
            configuraciones.me_emprepack_gratis_sucursal.value,
          me_emprepack_gratis_domicilio:
            configuraciones.me_emprepack_gratis_domicilio.value,
        },
        (err, resp) => {
          setState((prevState) => ({
            ...prevState,
            forms: {
              ...prevState.forms,
              configurar_solicitar_emprepack: false,
            },
          }));

          setAlert((prevState) => ({
            ...prevState,
            open: true,
            message: resp.message,
            type: err ? 'error' : 'success',
          }));

          if (!err && configuraciones.me_emprepack.value === 1) {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_activar.event_name,
              { metodo_envio: 'emprepack', success: true }
            );

            if (idCountry === 'ARG') {
              braze.logCustomEvent('empretienda_metodos_envío_activar', {
                metodo_envio: 'emprepack',
              });
            }
          } else {
            createAmplitudeEventWithDevice(
              method_shipment_amplitude_events.metodos_envio_desactivar
                .event_name,
              { metodo_envio: 'emprepack' }
            );
          }
        }
      );
    } else {
      Validate.validar_formulario_actualizar_obj(
        configuraciones,
        (new_configuraciones) => {
          setState((prevState) => ({
            ...prevState,
            forms: {
              ...prevState.forms,
              configurar_solicitar_emprepack: false,
            },
            configuraciones: new_configuraciones,
          }));

          setAlert((prevState) => ({
            ...prevState,
            open: true,
            message: 'Por favor, revisá tus datos, tenés algunos errores',
            type: 'error',
          }));
          createAmplitudeEventWithDevice(
            method_shipment_amplitude_events.metodos_envio_activar.event_name,
            { metodo_envio: 'emprepack', success: false }
          );
        }
      );
    }
  };

  const handleSubmitEditarDomicilio = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        editar_domicilio: true,
      },
    }));

    let { configuraciones, emprepack, kyc_domicilio } = state;

    if (
      Validate.validar_formulario(configuraciones) &&
      Validate.validar_formulario(emprepack)
    ) {
      if (state.configuraciones.me_emprepack_office_id.value > 0) {
        if (Validate.validar_formulario(kyc_domicilio)) {
          metodosenvioEditarDireccionEmprepack(
            {
              customer_street: emprepack.customer_street.value,
              customer_street_number: emprepack.customer_street_number.value,
              customer_floor: emprepack.customer_floor.value,
              customer_apartment: emprepack.customer_apartment.value,
              customer_city: emprepack.customer_city.value,
              customer_state: emprepack.customer_state.value,
              customer_zipcode: emprepack.customer_zipcode.value,
              me_emprepack_office_id:
                configuraciones.me_emprepack_office_id.value,
              comprobante_domicilio: kyc_domicilio.comprobante_domicilio.file,
            },
            (err, resp) => {
              setState((prevState) => ({
                ...prevState,
                forms: {
                  ...prevState.forms,
                  editar_domicilio: false,
                },
                emprepack: {
                  ...prevState.emprepack,
                  customer_status: {
                    ...prevState.emprepack.customer_status,
                    validate: false,
                    value: err ? -1 : 0,
                  },
                },
                modals: {
                  ...prevState.modals,
                  editar_domicilio: err ? true : false,
                },
              }));
              setAlert((prevState) => ({
                ...prevState,
                open: true,
                message: resp.message,
                type: err ? 'error' : 'success',
              }));
            }
          );
        } else {
          setState((prevState) => ({
            ...prevState,
            forms: {
              ...prevState.forms,
              editar_domicilio: false,
            },
          }));
          setAlert((prevState) => ({
            ...prevState,
            open: true,
            message:
              'Sube un documento que compruebe tu domicilio y este a tu nombre para verificar la dirección',
            type: 'error',
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            editar_domicilio: false,
          },
        }));
        setAlert((prevState) => ({
          ...prevState,
          open: true,
          message: 'Debes seleccionar una sucursal de entrega',
          type: 'error',
        }));
      }
    } else {
      Validate.validar_formulario_actualizar_obj(emprepack, (new_emprepack) => {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            editar_domicilio: false,
          },
          emprepack: new_emprepack,
        }));
        setAlert((prevState) => ({
          ...prevState,
          open: true,
          message: 'Por favor, revisá tus datos, tenés algunos errores',
          type: 'error',
        }));
      });
    }
  };

  const handleSubmitEditarContacto = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      forms: {
        ...prevState.forms,
        editar_contacto: true,
      },
    }));

    let { emprepack, kyc_dni } = state;

    if (Validate.validar_formulario(emprepack)) {
      if (Validate.validar_formulario(kyc_dni)) {
        metodosenvioEditarContactoEmprepack(
          {
            customer_email: emprepack.customer_email.value,
            customer_name: emprepack.customer_name.value,
            customer_surname: emprepack.customer_surname.value,
            customer_identification: emprepack.customer_identification.value,
            customer_phone: emprepack.customer_phone.value,
            dni_frontal: kyc_dni.dni_frontal.file,
            dni_reverso: kyc_dni.dni_reverso.file,
            dni_selfie: kyc_dni.dni_selfie.file,
          },
          (err, resp) => {
            setState((prevState) => ({
              ...prevState,
              forms: {
                ...prevState.forms,
                editar_contacto: false,
              },
              emprepack: {
                ...prevState.emprepack,
                customer_status: {
                  ...prevState.emprepack.customer_status,
                  validate: false,
                  value: err ? -1 : 0,
                },
              },
              modals: {
                ...prevState.modals,
                editar_contacto: err ? true : false,
              },
            }));
            setAlert((prevState) => ({
              ...prevState,
              open: true,
              message: resp.message,
              type: err ? 'error' : 'success',
            }));
          }
        );
      } else {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            editar_contacto: false,
          },
        }));

        setAlert((prevState) => ({
          ...prevState,
          open: true,
          message: 'Sube las fotos de tu DNI, del frente, reverso y selfie',
          type: 'error',
        }));
      }
    } else {
      Validate.validar_formulario_actualizar_obj(emprepack, (new_emprepack) => {
        setState((prevState) => ({
          ...prevState,
          forms: {
            ...prevState.forms,
            editar_contacto: false,
          },
          emprepack: new_emprepack,
        }));
        setAlert((prevState) => ({
          ...prevState,
          open: true,
          message: 'Por favor, revisá tus datos, tenés algunos errores',
          type: 'error',
        }));
      });
    }
  };

  const showSettings =
    state.emprepack.customer_status.value === -1 ||
    state.emprepack.customer_status.value > 0;

  const onSubmitFunc =
    state.emprepack.customer_status.value === -1
      ? handleSubmitSolicitarEmprepack
      : handleSubmitConfigurarEmprepack;

  //TODO: rearmar la estructura para que sea mas entendible
  return (
    <WrapperLayout
      error={isEmprepackDataError || isBillingDataError || isEmprepackDataError}
      loading={
        isShipmentMethodsLoading ||
        isBillingDataLoading ||
        isEmprepackDataLoading
      }
    >
      <ModalAddressData
        emprepack={state.emprepack}
        configuraciones={state.configuraciones}
        handleSubmit={handleSubmitEditarDomicilio}
        modalHandler={modalHandler}
        modal_state={state.modals.editar_domicilio}
        form_state={state.forms.editar_domicilio}
        kyc_domicilio={state.kyc_domicilio}
        handleChange={handleChange}
        handleChangeSelect={handleChangeSelect}
        handleChangeDoc={handleChangeDoc}
        handleChangeZipCode={handleChangeZipCode}
        customerZipcode={state.emprepack.customer_zipcode.value}
      />
      <ModalContactData
        emprepack={state.emprepack}
        handleSubmit={handleSubmitEditarContacto}
        modalHandler={modalHandler}
        modal_state={state.modals.editar_contacto}
        form_state={state.forms.editar_contacto}
        kyc_dni={state.kyc_dni}
        handleChange={handleChange}
        handleChangeDoc={handleChangeDoc}
      />
      <ModalTyC modal_state={state.modals.tos} modalHandler={modalHandler} />
      <AlertUI
        open={alert.open}
        message={alert.message}
        type={alert.type}
        handleCloseAlert={handleCloseAlert}
      />
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <Box component="form" onSubmit={onSubmitFunc}>
            <ModuleTitleUI
              title="Emprepack"
              subtitles={[
                <span>
                  Emprepack es una solución desarrollada por Empretienda para
                  poder utilizar el servicio de logística eCommerce de Andreani.
                </span>,
                <span>
                  Podés ver más información del servicio haciendo click{' '}
                  <u>
                    <a
                      href="https://recursos.empretienda.com/emprepack.pdf"
                      rel="noreferrer"
                      target="_blank"
                      style={{ color: 'inherit' }}
                    >
                      aquí
                    </a>
                  </u>
                </span>,
              ]}
              image={`${Config.CLOUDFRONT_CDN_PROD}/panel/logos-integraciones/emprepack-logo.png`}
              button={
                <ConfigurationMenu
                  customer_status={state.emprepack.customer_status.value}
                  modalHandler={modalHandler}
                />
              }
            />
            <ToggleUI
              show={
                state.emprepack.customer_status.value === -1 &&
                state.facturacion_activa === 0
              }
            >
              <BillDataEmprepack />
            </ToggleUI>
            <ToggleUI show={state.emprepack.customer_status.value === 0}>
              <AccountState
                customer_status={state.emprepack.customer_status.value}
              />
            </ToggleUI>
            <ToggleUI show={state.facturacion_activa === 1 && showSettings}>
              <AccountState
                customer_status={state.emprepack.customer_status.value}
              />
              <CardModuleUI
                below
                show={state.emprepack.customer_status.value === -1}
                with_toggle
              >
                <ShipmentContactData
                  emprepack={state.emprepack}
                  kyc_dni={state.kyc_dni}
                  handleChange={handleChange}
                  handleChangeDoc={handleChangeDoc}
                />
              </CardModuleUI>
              <CardModuleUI
                below
                show={state.emprepack.customer_status.value === -1}
                with_toggle
              >
                <ShipmentAddressData
                  emprepack={state.emprepack}
                  configurations={state.configuraciones}
                  kyc_domicilio={state.kyc_domicilio}
                  handleChange={handleChange}
                  handleChangeSelect={handleChangeSelect}
                  handleChangeDoc={handleChangeDoc}
                  handleChangeZipCode={handleChangeZipCode}
                  customerZipcode={state.emprepack.customer_zipcode.value}
                />
              </CardModuleUI>
              <FreeShipmentData
                configurations={state.configuraciones}
                handleChange={handleChange}
                handleChangeSelect={handleChangeSelect}
                handleChangeCheckbox={handleChangeCheckbox}
              />
              <ShipmentWithChargeData
                configurations={state.configuraciones}
                handleChange={handleChange}
              />
              <TyCSection
                modalHandler={modalHandler}
                handleChangeCheckbox={handleChangeCheckbox}
                checkboxValue={state.configuraciones.me_emprepack}
              />
              <Box mt="20px" maxWidth={{ xs: '100%', md: '256px' }}>
                <ButtonMui
                  type="submit"
                  fullWidth
                  isLoading={state.forms.configurar_solicitar_emprepack}
                >
                  {state.emprepack.customer_status.value === -1
                    ? 'Solicitar activación'
                    : 'Guardar'}
                </ButtonMui>
              </Box>
            </ToggleUI>
          </Box>
        </Grid>
      </Grid>
    </WrapperLayout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    metodosenvioSolicitarEmprepack: (data, callback) =>
      dispatch(metodosenvioSolicitarEmprepack(data, callback)),
    metodosenvioEditarDireccionEmprepack: (data, callback) =>
      dispatch(metodosenvioEditarDireccionEmprepack(data, callback)),
    metodosenvioEditarContactoEmprepack: (data, callback) =>
      dispatch(metodosenvioEditarContactoEmprepack(data, callback)),
    metodoenvioConfigurarEmprepack: (data, callback) =>
      dispatch(metodoenvioConfigurarEmprepack(data, callback)),
  };
};

const EmprepackPage = connect(null, mapDispatchToProps)(Emprepack_);
export default EmprepackPage;
