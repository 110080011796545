export const getHomeMessages = (state, tienda) => {
  const red = 'error';
  const green = 'primary';

  const factura = state.proximo_vencimiento;

  const alwaysShowBanner = JSON.parse(
    localStorage.getItem('showSuscriptionBanner')
  );

  if (tienda.t_debito_automatico === 0 && tienda.t_pago_manual === 0) {
    if (tienda.t_prueba === 1) {
      if (factura.paga === 0 && factura.periodo_prueba === 1) {
        if (factura.dias_vencimiento >= 1) {
          return {
            title: {
              text:
                factura.dias_vencimiento > 1
                  ? `Tenés ${factura.dias_vencimiento} días de prueba`
                  : 'Te queda 1 día de prueba',
            },
            subtitle: {
              text: 'Podés suscribirte ahora y seguir disfrutando de tu período gratuito.',
            },
            button: {
              text: 'Suscribirme',
              color: green,
            },
            showBanner: factura.dias_vencimiento > 10 ? alwaysShowBanner : true,
            canBeClosed: true,
          };
        } else if (factura.dias_vencimiento === 0) {
          return {
            title: {
              text: 'Hoy finalizan tus días de prueba',
            },
            subtitle: {
              text: 'Para continuar con tu tienda, elegí un método de pago.',
            },
            button: {
              text: 'Suscribirme',
              color: red,
            },
            showBanner: true,
            canBeClosed: false,
          };
        } else if (factura.dias_vencimiento > 30) {
          return {
            showBanner: false,
            canBeClosed: true,
          };
        } else {
          return {
            title: {
              text: 'Finalizaron tus días de prueba',
            },
            subtitle: {
              text: 'Para continuar con tu tienda, elegí un método de pago.',
            },
            button: {
              text: 'Suscribirme',
              color: red,
            },
            showBanner: true,
            canBeClosed: false,
          };
        }
      }
    } else {
      if (factura.dias_vencimiento > 0 && factura.dias_vencimiento < 11) {
        //aun no vencio
        return {
          title: {
            text: 'Ya podés pagar tu plan',
          },
          subtitle: {
            text: `Tu plan vence el ${factura.fecha_vencimiento}.`,
          },
          button: {
            text: 'Pagar plan',
            color: green,
          },
          showBanner: true,
          canBeClosed: true,
        };
      } else if (factura.dias_vencimiento === 0) {
        return {
          title: {
            text: 'Tu plan vence hoy ',
          },
          subtitle: {
            text: `Para continuar con tu tienda, elegí un método de pago.`,
          },
          button: {
            text: 'Pagar plan',
            color: red,
          },
          showBanner: true,
          canBeClosed: false,
        };
      } else if (
        (factura.dias_vencimiento > 10 && factura.dias_vencimiento < 30) ||
        factura.dias_vencimiento >= 30
      ) {
        return {
          showBanner: false,
          canBeClosed: true,
        };
      } else {
        //ya vencio, esta en deuda o con dias de gracia

        return {
          title: {
            text: `Tu plan venció el ${factura.fecha_vencimiento}.`,
          },
          subtitle: {
            text: `Para continuar con tu tienda, elegí un método de pago.`,
          },
          button: {
            text: 'Pagar plan',
            color: red,
          },
          showBanner: true,
          canBeClosed: false,
        };
      }
    }
  } else if (tienda.t_debito_automatico === 1) {
    if (tienda.t_prueba === 1) {
      return {
        showBanner: false,
        canBeClosed: true,
      };
    }
  } else if (tienda.t_pago_manual === 1) {
    if (factura.paga === 0 && factura.dias_vencimiento <= 10) {
      if (factura.dias_vencimiento >= 0) {
        //el plan aun no vencio
        return {
          title: {
            text: 'Ya podés pagar tu plan',
          },
          subtitle: {
            text: `Tu plan vence el ${factura.fecha_vencimiento}.`,
          },
          button: {
            text: 'Pagar plan',
            color: green,
          },
          showBanner: true,
          canBeClosed: true,
        };
      } else {
        //ya vencio, esta en deuda o con dias de gracia

        return {
          title: {
            text: tienda.t_deuda
              ? `Tu plan venció el ${factura.fecha_vencimiento} y tu tienda ha sido deshabilitada por falta de pago.`
              : `Tu plan vence hoy`,
          },
          subtitle: {
            text: `Para continuar con tu tienda, elegí un método de pago.`,
          },
          button: {
            text: 'Pagar plan',
            color: red,
          },
          showBanner: true,
          canBeClosed: false,
        };
      }
    }
  }
  return {
    showBanner: false,
    canBeClosed: true,
  };
};
