export const optStates = [
  {
    value: 'Buenos Aires',
    label: 'Buenos Aires',
  },
  {
    value: 'C.A.B.A',
    label: 'C.A.B.A',
  },
  {
    value: 'Catamarca',
    label: 'Catamarca',
  },
  {
    value: 'Chaco',
    label: 'Chaco',
  },
  {
    value: 'Chubut',
    label: 'Chubut',
  },
  {
    value: 'Córdoba',
    label: 'Córdoba',
  },
  {
    value: 'Corrientes',
    label: 'Corrientes',
  },
  {
    value: 'Entre Ríos',
    label: 'Entre Ríos',
  },
  {
    value: 'Formosa',
    label: 'Formosa',
  },
  {
    value: 'Jujuy',
    label: 'Jujuy',
  },
  {
    value: 'La Pampa',
    label: 'La Pampa',
  },
  {
    value: 'La Rioja',
    label: 'La Rioja',
  },
  {
    value: 'Mendoza',
    label: 'Mendoza',
  },
  {
    value: 'Misiones',
    label: 'Misiones',
  },
  {
    value: 'Neuquén',
    label: 'Neuquén',
  },
  {
    value: 'Río Negro',
    label: 'Río Negro',
  },
  {
    value: 'Salta',
    label: 'Salta',
  },
  {
    value: 'San Juan',
    label: 'San Juan',
  },
  {
    value: 'San Luis',
    label: 'San Luis',
  },
  {
    value: 'Santa Cruz',
    label: 'Santa Cruz',
  },
  {
    value: 'Santa Fe',
    label: 'Santa Fe',
  },
  {
    value: 'Santiago del Estero',
    label: 'Santiago del Estero',
  },
  {
    value: 'Tierra del Fuego',
    label: 'Tierra del Fuego',
  },
  {
    value: 'Tucumán',
    label: 'Tucumán',
  },
];
export const optFreeShipment = [
  {
    value: 0,
    label: 'No',
  },
  {
    value: 1,
    label: 'Si (Para todos los productos)',
  },
  {
    value: 2,
    label: 'Si (Ventas mayores a cierto monto)',
  },
];
