import React from 'react';
import { DocFileUI } from '../../../../components/common';

export const DocKyc = ({ input, handleChangeDoc }) => {
  const allowed_files = ['pdf', 'jpeg', 'jpg', 'png'];
  const max_size = 3;
  const input_allowed_mime = 'image/jpg,image/jpeg,image/png,application/pdf';

  return (
    <DocFileUI
      data={input}
      handleChangeDoc={handleChangeDoc}
      max_size={max_size}
      allowed_files={allowed_files}
      input_allowed_mime={input_allowed_mime}
    />
  );
};
