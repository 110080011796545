import { Grid } from '@mui/material';
import React from 'react';
import { InputUI, ModuleCardTitleUI } from '../../../../components/common';
import { DocKyc } from './DocKyc';

export const ShipmentContactData = ({
  emprepack,
  kyc_dni,
  handleChange,
  handleChangeDoc,
}) => {
  return (
    <Grid container spacing={1.5}>
      <ModuleCardTitleUI
        title="Información de contacto"
        subtitles={[
          'Esta información será utilizada para contactarte en caso de ser necesario.',
        ]}
      />
      <Grid item xs={12}>
        <InputUI handleChange={handleChange} input={emprepack.customer_email} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <InputUI handleChange={handleChange} input={emprepack.customer_name} />
      </Grid>
      <Grid item lg={6} xs={12}>
        <InputUI
          handleChange={handleChange}
          input={emprepack.customer_surname}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <InputUI
          handleChange={handleChange}
          input={emprepack.customer_identification}
        />
      </Grid>
      <Grid item lg={6} xs={12}>
        <InputUI handleChange={handleChange} input={emprepack.customer_phone} />
      </Grid>
      <Grid item xs={12}>
        <DocKyc handleChangeDoc={handleChangeDoc} input={kyc_dni.dni_frontal} />
      </Grid>
      <Grid item xs={12}>
        <DocKyc handleChangeDoc={handleChangeDoc} input={kyc_dni.dni_reverso} />
      </Grid>
      <Grid item xs={12}>
        <DocKyc handleChangeDoc={handleChangeDoc} input={kyc_dni.dni_selfie} />
      </Grid>
    </Grid>
  );
};
