import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Config } from '../other';

const fetchData = async () => {
  const { data } = await axios.get(
    Config.BACKEND_ENDPOINT + '/medios-envio/emprepack'
  );
  return data.data;
};

const useGetEmprepackData = () => {
  return useQuery({
    refetchOnMount: false,
    queryKey: ['emprepackData'],
    queryFn: fetchData,
    gcTime: 12 * 60 * 60 * 1000, // 12 hours
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });
};

export default useGetEmprepackData;
