import React from 'react';
import { ModalUI } from '../../../../components/common';
import { ShipmentAddressData } from './ShipmentAddressData';

export const ModalAddressData = ({
  emprepack,
  configuraciones,
  handleSubmit,
  modalHandler,
  modal_state,
  form_state,
  kyc_domicilio,
  handleChange,
  handleChangeSelect,
  handleChangeDoc,
  filtrarSucursales,
  handleChangeZipCode,
  customerZipcode,
}) => {
  return (
    <ModalUI
      open={modal_state}
      id="editar_domicilio"
      title="Editar domicilio"
      subtitle="Tene en cuenta que al editar el domicilio, se reiniciará el proceso de alta con una demora de 72hs. En ese tiempo su tienda no podrá utilizar Emprepack."
      modalHandler={modalHandler}
      aditional_param="editar_domicilio"
      handleSubmit={handleSubmit}
      button_label="Editar"
      button_loading={form_state}
    >
      <ShipmentAddressData
        emprepack={emprepack}
        configuraciones={configuraciones}
        kyc_domicilio={kyc_domicilio}
        handleChange={handleChange}
        handleChangeSelect={handleChangeSelect}
        handleChangeDoc={handleChangeDoc}
        filtrarSucursales={filtrarSucursales}
        handleChangeZipCode={handleChangeZipCode}
        customerZipcode={customerZipcode}
      />
    </ModalUI>
  );
};
