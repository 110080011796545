import { IconButton, Box, Typography } from '@mui/material';
import React from 'react';
import { ButtonUI, ToggleUI } from '../../../components/common';
import ClearIcon from '@mui/icons-material/Clear';
import { BannerContainer, BannerContent, Container } from './styles';

import { useHistory } from 'react-router-dom';

import {
  amplitude_events,
  createAmplitudeEventWithDevice,
} from '../../../components/amplitude';

export const SuscriptionBanner = ({
  messages,
  isBannerOpen,
  setIsBannerOpen,
}) => {
  const { title, subtitle, button, showBanner, canBeClosed } = messages;

  const history = useHistory();

  const handleOnClick = () => {
    createAmplitudeEventWithDevice(
      amplitude_events.home_banner_selecciona_suscribirme.event_name
    );

    history.push('/plan/metodo-pago');
  };

  return (
    <ToggleUI show={showBanner && isBannerOpen}>
      <BannerContainer item xs={12}>
        <Container>
          <BannerContent
            sx={{
              padding: !canBeClosed ? '16px 34px' : null,
              width: { xs: '100%', md: 'auto' },
            }}
          >
            <Box>
              <Typography
                as="h3"
                sx={{
                  fontSize: '1.12rem',
                  fontWeight: '500',
                }}
              >
                {title?.text}
              </Typography>
              <Typography
                as="p"
                sx={{
                  fontSize: '1rem',
                  margin: { xs: '0px 0px 24px 0px', md: '0px' },
                }}
              >
                {subtitle?.text}
              </Typography>
            </Box>
            <ButtonUI
              type="callf"
              label={button?.text}
              color={button?.color}
              fullWidth={false}
              onClickFunc={handleOnClick}
              sx={{
                marginLeft: { xs: '0px', md: '32px' },
                width: { xs: '100%', md: '158px' },
                maxHeight: { xs: 'unset', md: '40px' },
              }}
            />
            <ToggleUI show={canBeClosed}>
              <IconButton
                sx={{
                  position: 'absolute',
                  color: '#565656',
                  top: { xs: '8px', md: 'auto' },
                  right: { xs: '24px', md: '26px' },
                }}
                onClick={() => {
                  setIsBannerOpen(false);
                  localStorage.setItem('showSuscriptionBanner', false);
                }}
              >
                <ClearIcon />
              </IconButton>
            </ToggleUI>
          </BannerContent>
        </Container>
      </BannerContainer>
    </ToggleUI>
  );
};
