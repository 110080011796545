import { Grid } from '@mui/material';
import React from 'react';
import {
  CardModuleUI,
  CheckboxUI,
  InputUI,
  ModuleCardTitleUI,
  SelectUI,
  ToggleUI,
} from '../../../../components/common';
import { optFreeShipment } from './jsonSelect';

export const FreeShipmentData = ({
  handleChange,
  configurations,
  handleChangeSelect,
  handleChangeCheckbox,
}) => {
  return (
    <CardModuleUI below>
      <Grid container spacing={1.5}>
        <ModuleCardTitleUI
          title="Envío gratis"
          subtitles={[
            'En esta sección podés configurar los envíos gratuitos a través de Emprepack.',
          ]}
        />
        <Grid item xs={12}>
          <SelectUI
            data={configurations.me_emprepack_tipo}
            options={optFreeShipment}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <ToggleUI show={configurations.me_emprepack_tipo.value === 2}>
          <Grid item xs={12}>
            <InputUI
              handleChange={handleChange}
              input={configurations.me_emprepack_gratis_minimo}
              adornment="$"
            />
          </Grid>
        </ToggleUI>
        <ToggleUI show={configurations.me_emprepack_tipo.value >= 1}>
          <Grid item xs={12}>
            <CheckboxUI
              handleChangeCheckbox={handleChangeCheckbox}
              checkbox={configurations.me_emprepack_gratis_sucursal}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxUI
              handleChangeCheckbox={handleChangeCheckbox}
              checkbox={configurations.me_emprepack_gratis_domicilio}
            />
          </Grid>
        </ToggleUI>
      </Grid>
    </CardModuleUI>
  );
};
