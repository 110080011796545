import React from 'react';
import {
  ButtonUI,
  CardModuleUI,
  ModuleCardTitleUI,
} from '../../../../components/common';
import { Grid } from '@mui/material';

export const BillDataEmprepack = () => {
  return (
    <CardModuleUI below>
      <Grid container spacing={1.5}>
        <ModuleCardTitleUI
          title={
            'Para poder activar Emprepack debes cargar tus datos de facturación desde Mi tienda -> Mi plan -> Configuración -> Datos de facturación'
          }
        />
        <Grid item xs={12}>
          <ButtonUI type="link" label="Cargar" fullWidth={false} link="/plan" />
        </Grid>
      </Grid>
    </CardModuleUI>
  );
};
