import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '../actions';
import tiendaDummy from '../../__mocks__/tiendaDummy';

const createShop = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/1/shop', data)
      .then((resp) => {
        const data = resp.data.data;

        const auth_data = {
          d_nombre: data.dominio.d_nombre,
          t_nombre: data.tienda.t_nombre,
        };

        localStorage.setItem('first-login', 1);
        dispatch({ type: Reducers.AUTH_SET, data: auth_data });

        return callback(false, null);
      })
      .catch((err) => {
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaObtener = (callback) => {
  if (Config.MOCK_ACTIVE) {
    const error = false;
    const resp = tiendaDummy.tiendaObtener();

    return () => new Promise((resolve) => resolve(callback(error, resp)));
  }

  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/tienda')
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        dispatch({
          type: Reducers.AUTH_ACTUALIZAR_NOMBRE,
          data: { t_nombre: tienda.t_nombre },
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaObtenerOverview = (callback) => {
  if (Config.MOCK_ACTIVE) {
    const error = false;
    const resp = tiendaDummy.tiendaObtenerOverview();

    return () => new Promise((resolve) => resolve(callback(error, resp)));
  }

  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/tienda/overview')
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaObtenerMiPlan = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/tienda/plan')
      .then((resp) => {
        const tienda = resp.data.data.tienda;
        dispatch({
          type: Reducers.TIENDA_GUARDAR,
          data: tienda,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaObtenerMiPlanWithCode = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/tienda/plan/with-code', data)
      .then((resp) => {
        const tienda = resp.data.data.tienda;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaInformarPago = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/plan/informar-pago', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaObtenerUltimoPago = (callback) => {
  if (Config.MOCK_ACTIVE) {
    const error = false;
    const resp = tiendaDummy.tiendaObtenerUltimoPago();

    return () => new Promise((resolve) => resolve(callback(error, resp)));
  }

  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/tienda/pago')
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const shopBillDownload = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(`${Config.BACKEND_ENDPOINT}/tienda/comprobante/${data}`, {
        responseType: 'blob',
      })
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarGeneral = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/configuracion-general', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        dispatch({
          type: Reducers.AUTH_ACTUALIZAR_NOMBRE,
          data: { t_nombre: tienda.t_nombre },
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaConfigurarAlertaEnvio = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/alerta-envio', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarRedesSociales = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/redes-sociales', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaAgregarCuponReferido = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/tienda/cupon-referido', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarDatosFacturacion = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/datos-facturacion', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaPagarFactura = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/pagar-factura/' + data.id, {
        metodo: data.metodo,
      })
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaDesactivarMediosPagoSuscripcion = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/desactivar-medio-pago')
      .then((resp) => {
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaBajaSuscripcion = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/baja-debito-automatico')
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaAltaSuscripcion = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/alta-debito-automatico', {
        card_token: data.cardToken,
        user_email: data.userMail,
      })
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaModificarSuscripcion = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/modificar-debito-automatico', {
        new_card_token: data.cardToken,
        t_email_mp: data.userMail,
      })
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaAdherirPagoManual = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/alta-pago-manual')
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarChat = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/chat', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarEtiquetasHtml = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/etiquetas-html', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarDataFiscal = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/data-fiscal', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarPinterestTag = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/pinterest-tag', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarFacebookPixel = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/facebook-pixel', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarSeoDescripcion = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/seo-descripcion', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarFormularioContacto = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/formulario-contacto', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarProductosDigitales = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/productos-digitales', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarVentaMayorista = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/venta-mayorista', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarMarket = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/market', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarNotificacionesStock = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/notificaciones-stock', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaConfigurarNotificacionesStock = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/tienda/configurar-notificaciones-stock',
        data
      )
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarEmailMarketing = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/email-marketing', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarGoogleAnalytics = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/google-analytics', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarApagarTemporalmente = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/apagar-temporalmente', data)
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaBlogActivar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/activar-blog')
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaBlogDesactivar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/desactivar-blog')
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaPaginasActivar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/activar-paginas')
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaPaginasDesactivar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/desactivar-paginas')
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaCuponesActivar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/activar-cupones')
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaActualizarTemplate = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/template', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaGenerarPrevisualizacionTemplate = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .post(Config.BACKEND_ENDPOINT + '/tienda/template/preview', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaCuponesDesactivar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/desactivar-cupones')
      .then((resp) => {
        //guardo en redux
        const tienda = resp.data.data;
        dispatch({ type: Reducers.TIENDA_GUARDAR, data: tienda });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const tiendaDarBaja = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/tienda/baja', data)
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const uploadImageEditor = (data, callback) => {
  return (dispatch, _getState) => {
    let body = new FormData();
    body.append('max_width', 960);
    body.append('max_height', 1080);
    body.append('images[]', data.file);

    axios
      .post(Config.BACKEND_ENDPOINT + '/upload/texteditor', body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((resp) => {
        resp.data.data = {
          link: Config.CLOUDFRONT_DESCIMAGES_CDN + resp.data.data[0],
        };

        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const planObtenerEstados = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/states/col')
      .then((resp) => {
        //guardo en redux

        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const planObtenerCiudades = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/cities/col')
      .then((resp) => {
        //guardo en redux

        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  createShop,
  tiendaObtener,
  tiendaObtenerOverview,
  tiendaObtenerUltimoPago,
  tiendaActualizarGeneral,
  tiendaConfigurarAlertaEnvio,
  tiendaObtenerMiPlanWithCode,
  tiendaInformarPago,
  tiendaObtenerMiPlan,
  tiendaActualizarRedesSociales,
  tiendaAgregarCuponReferido,
  tiendaActualizarDatosFacturacion,
  tiendaActualizarChat,
  tiendaActualizarEtiquetasHtml,
  tiendaActualizarDataFiscal,
  tiendaActualizarPinterestTag,
  tiendaPagarFactura,
  tiendaDesactivarMediosPagoSuscripcion,
  tiendaAltaSuscripcion,
  tiendaBajaSuscripcion,
  tiendaModificarSuscripcion,
  tiendaActualizarFacebookPixel,
  tiendaActualizarSeoDescripcion,
  tiendaActualizarFormularioContacto,
  tiendaActualizarVentaMayorista,
  tiendaActualizarMarket,
  tiendaActualizarEmailMarketing,
  tiendaActualizarGoogleAnalytics,
  tiendaActualizarApagarTemporalmente,
  tiendaActualizarProductosDigitales,
  tiendaActualizarNotificacionesStock,
  tiendaConfigurarNotificacionesStock,
  tiendaBlogActivar,
  tiendaBlogDesactivar,
  tiendaPaginasActivar,
  tiendaAdherirPagoManual,
  tiendaActualizarTemplate,
  tiendaGenerarPrevisualizacionTemplate,
  tiendaPaginasDesactivar,
  tiendaCuponesActivar,
  tiendaCuponesDesactivar,
  tiendaDarBaja,
  uploadImageEditor,
  planObtenerEstados,
  planObtenerCiudades,
  shopBillDownload,
};
