import React from 'react';
import { ModalUI } from '../../../../components/common';
import { Grid, Typography } from '@mui/material';

export const ModalTyC = ({ modalHandler, modal_state }) => {
  return (
    <ModalUI
      open={modal_state}
      id="tos"
      title="TÉRMINOS Y CONDICIONES EMPREPACK – ANDREANI"
      modalHandler={modalHandler}
      aditional_param="tos"
      handleSubmit={(e) => {
        e.preventDefault();
        modalHandler(false, 'tos');
      }}
      button_label="Cerrar"
      wide_modal={true}
    >
      <Grid container spacing={1.5}>
        <Grid item>
          <Typography variant="h5" gutterBottom>
            TÉRMINOS Y CONDICIONES EMPREPACK – ANDREANI
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            1. ACERCA DE EMPREPACK
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            EMPREPACK es una solución desarrollada por EMPRETIENDA para poder
            utilizar el servicio de logística eCommerce de ANDREANI.
            <br />
            Los emprendedores y los Usuarios podrán ofrecer y coordinar el envío
            de los productos comercializados a través de las tiendas de
            EMPRETIENDA mediante el servicio de transporte y/o logística y/o
            correo postal brindado por ANDREANI.
            <br />
            EMPREPACK, le permite al emprendedor acceder al servicio de
            eCommerce de ANDREANI sin tener que cumplimentar el mínimo de envíos
            requeridos por dicha firma. EMPREPACK se limita a calcular el valor
            del envío del producto previo a la compra y a facilitar la
            generación de las etiquetas necesarias para el posterior envío del
            producto a través de la firma ANDREANI.
            <br />
            ANDREANI será la firma responsable y encargada de realizar
            directamente los servicios de envíos a favor de los compradores
            cuando los mismos sean requeridos a través de la plataforma de
            EMPREPACK. Los compradores y emprendedores celebran con dicha firma
            un contrato de transporte de mercaderías que se regirá por lo
            convenido entre las partes y la normativa aplicable al caso.
            <br />
            EMPREPACK sólo facilita la herramienta para que el Usuario o
            emprendedor pueda acceder de forma más cómoda, efectiva y accesible
            al servicio de logística y entrega de los productos adquiridos en
            las tiendas de EMPRETIENDA.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            2. TÉRMINOS Y CONDICIONES DE EMPREPACK
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Los términos y condiciones de EMPREPACK son parte de los Términos y
            Condiciones de EMPRETIENDA.
            <br />
            La aceptación de los términos de EMPREPACK implica la aceptación de
            los términos y condiciones de EMPRETIENDA y de las condiciones
            negociales que estipule ANDREANI en sus contratos de transporte.
            <br />
            Los presentes términos y condiciones (en adelante los “Términos y
            Condiciones”) tienen carácter vinculante y obligatorio y son
            aplicados a la utilización de los servicios EMPREPACK, cuyo
            responsable es EMPRETIENDA SAS, CUIT 30-71671409-4 con domicilio en
            calle 20 N°1406 Las Parejas, Santa Fe, Argentina.
            <br />
            La utilización de los Servicios EMPREPACK atribuye la condición de
            usuario y/o emprendedor del Sitio e implica que usted tiene la
            capacidad legal para suscribir este contrato y que presta la
            conformidad y aceptación voluntaria, expresa, plena y sin reservas,
            de todos los derechos y obligaciones que asume, como así también de
            cada una de las cláusulas de los Términos y Condiciones y de la
            políticas de privacidad de datos personales de EMPRETIENDA Y
            EMPREPACK, publicadas en los sitios de la empresa.
            <br />
            En caso de no estar de acuerdo con los mismos deberá abstenerse de
            utilizar los Servicios. Tanto el comprador como el emprendedor
            consienten expresamente que todos los datos incorporados que sean
            necesarios para el uso de EMPREPACK sean recolectados y almacenados
            y se le dará el tratamiento estipulado en las políticas de
            privacidad de EMPRETIENDA.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            3. REGISTRACIÓN - ACTIVAR EMPREPACK EN LA TIENDA
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Para contar con el servicio EMPREPACK ANDREANI el emprendedor deberá
            solicitar el alta de su cuenta EMPREPACK, accediendo a la opción
            desde Mi tienda &gt; Métodos de envío, y seleccionar la opción de
            EMPREPACK/ANDREANI. El emprendedor deberá acreditar su identidad y
            remitir la documentación solicitada a EMPRETIENDA, quién tendrá un
            plazo de 1 semana para revisar el cumplimiento de los requisitos
            solicitados.
            <br />
            EMPREPACK tiene el derecho de rechazar, cancelar o suspender con o
            sin causa una solicitud o el uso de los servicios de EMPREPACK, sin
            que tal decisión genere para el Usuario derechos de indemnización o
            resarcimiento.
            <br />
            El Emprendedor con una cuenta EMPREPACK activa podrá ofrecer en sus
            publicaciones el servicio de envío EMPREPACK – ANDREANI y quedará
            sujeto a las condiciones y disponibilidad del servicio que
            establezca ANDREANI para el producto ofrecido. El producto deberá
            ser apto para ser transportado por ANDREANI; y el destino del envío
            debe ser un domicilio válido dentro del territorio de la República
            Argentina y encontrarse dentro de los destinos operativos y
            cubiertos por la firma ANDREANI.
            <br />
            El emprendedor es responsable de seleccionar la categoría más
            adecuada, configurar las correctas dimensiones para el producto
            publicado y verificar la disponibilidad del Servicio de ANDREANI
            para ese bien. Cualquier consecuencia derivada de la categorización
            incorrecta del bien al ser publicado, diferentes dimensiones entre
            las configuradas y efectivamente despachadas o de la omisión de la
            verificación de las limitaciones respecto del Servicio de ANDREANI
            será asumida por el emprendedor (por ejemplo, mayor costo del envío
            o imposibilidad de transportar el bien).
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            4. FUNCIONAMIENTO DEL SERVICIO. PAGO DEL SERVICIO
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Una vez enviada y aceptada la solicitud de cuenta de EMPREPACK, el
            emprendedor contará con esta opción de envío y en su tienda
            comenzará a calcularse (tarifarse) el costo del envío a través de
            EMPREPACK.
            <br />
            El cálculo de la tarifa dependerá del domicilio del emprendedor y/o
            lugar de entrega y/o retiro del producto vendido, las dimensiones,
            peso y/u otras características de los productos y el código postal
            de destino que selecciona el cliente.
            <br />
            EMPREPACK publicará la opción de envío para que los compradores
            puedan elegir el servicio.
            <br />
            Cuando un comprador seleccione la opción de EMPREPACK ANDREANI en
            una tienda abonará el total de la orden al emprendedor, productos +
            envío, a través del método de pago que seleccione.
            <br />
            Una vez que el emprendedor reciba el pago podrá dar de alta la
            etiqueta del envío desde el menú de acciones de ventas del panel
            administrador de EMPRETIENDA y acceder al link directo para abonarle
            a EMPRETIENDA el costo del envío a través de Mercado Pago ó Mobbex.
            EMPRETIENDA será la encargada de pagarle a ANDREANI el servicio.
            <br />
            Una vez abonado por el EMPRENDEDOR el envío, podrá descargar del
            menú de acciones la etiqueta para imprimir.
            <br />
            En caso que el EMPRENDEDOR haya optado porque el costo del envío
            esté a cargo del comprador, el EMPRENDEDOR se obliga a incluir en el
            documento fiscal correspondiente el costo de envío y el precio del
            producto.
            <br />
            El EMPRENDEDOR se obliga expresamente a no cobrar ni solicitar sumas
            adicionales de dinero alguno al comprador por cuestiones
            relacionadas al uso de EMPREPACK.
            <br />
            Una vez que se da de alta el envío, el comprador puede hacer un
            seguimiento del mismo desde la tienda online desde la sección "Mi
            cuenta" o en el sitio de ANDREANI.
            <br />
            Al hacer click en "seguir envío" le va a aparecer un recuadro con el
            estado del mismo. Una vez que efectivamente se despache el paquete y
            sea ingresado al sistema de ANDREANI, ellos enviaran un mail con
            toda la información. La recepción de los mails enviados desde
            ANDREANI queda sujeta a la disponibilidad de su servicio y correctos
            datos cargados por el comprador.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            5. COSTO A CARGO DEL EMPRENDEDOR
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            El emprendedor podrá ofrecer en sus publicaciones la opción de
            “Envío gratis”.
            <br />
            El emprendedor se hará cargo de todos los costos por el uso de
            EMPREPACK modalidad de “Envío gratis”.
            <br />
            El emprendedor está obligado a entregar el envío (paquete
            conteniendo los bienes a ser transportados) en cualquier punto de
            admisión de ANDREANI debidamente autorizado o solicitar su retiro
            (en caso que tuviera esta opción disponible y con su respectivo
            costo adicional) en las condiciones requeridas para paquete y
            producto. Asimismo, deberá respetar lo establecido para la fecha de
            entrega para evitar que sea rechazado el envío.
            <br />
            En el supuesto que ANDREANI rechace el envío por no haber cumplido
            con las condiciones estipuladas el emprendedor deberá negociar
            directamente con el comprador la forma de envío del producto. En
            este caso el emprendedor se hará cargo íntegramente de cualquier
            cargo extra originado por el envío del producto.
            <br />
            El emprendedor deberá entregar el envío con la etiqueta respectiva y
            pegada adecuadamente, con información precisa respecto del paquete,
            contenido y destino. La elección incorrecta de las etiquetas es
            responsabilidad exclusiva del emprendedor, quién se hará cargo de
            daños y perjuicios resultantes de este incumplimiento.
            <br />
            El emprendedor deberá configurar correctamente las dimensiones de
            los bienes en su tienda. En caso que el emprendedor despache en
            ANDREANI un paquete de mayor dimensiones a las configuradas será su
            exclusiva responsabilidad, se hará cargo de abonar la diferencia de
            precio a EMPRETIENDA y de daños y perjuicios resultantes de este
            incumplimiento.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            6. PROCEDIMIENTO PARA UTILIZAR EMPREPACK
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Una vez activo el método de envío EMPREPACK en la tienda, el
            comprador ingresa el código postal y/o domicilio donde desea recibir
            el envío y recibirá información sobre la disponibilidad del servicio
            y sus costos.
            <br />
            El costo del envío será visualizado separadamente del valor del
            producto seleccionado y podrá ser abonado mediante los métodos de
            pago disponibles en la tienda del emprendedor.
            <br />
            El comprador que decida adquirir un producto donde la publicación
            ofrezca el envío por EMPREPACK, deberá brindar toda la información
            completa y correcta para el envío y recepción del producto. El
            comprador reconoce que será el único responsable por las
            consecuencias resultantes de un incumplimiento de dicha obligación.
            <br />
            Una vez que el comprador haya completado la información para el
            envío del producto en cuestión, abonado el valor del producto
            ofertado y el costo del envío calculado por ANDREANI (en caso de
            corresponder), el emprendedor recibirá la información cargada por el
            comprador para el envío del producto y procederá a pagar el envío a
            EMPRETIENDA, imprimir las etiquetas para el envío del producto,
            cerrar y empaquetar correctamente el producto a enviar, respetando
            para ello la naturaleza del mismo según corresponda, siguiendo las
            directivas que establezca ANDREANI.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            7. PROCEDIMIENTO EN CASO DE NO ENTREGA
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Una vez despachado efectivamente el producto e ingresado al sistema
            de ANDREANI, ellos enviarán un mail con toda la información respecto
            a la entrega y/o retiro. En caso de no entrega y/o inconvenientes
            con el envío los emprendedores deberán dirigirse vía mail a
            hola@emprepack.com informando el número de seguimiento del envío y
            detallando el problema.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            8. CANCELACIÓN DE LA OPERACIÓN POR CUALQUIER CAUSA
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            En caso que el comprador decida devolver el artículo al emprendedor
            o se arrepienta o cancele la transacción luego del envío del
            artículo, EMPRETIENDA no reintegrará el monto abonado por el envío
            ya que el mismo ha sido utilizado. En este caso, será el emprendedor
            quién asuma este costo frente al usuario.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            9. RESPONSABILIDAD DEL EMPRENDEDOR
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            El emprendedor se compromete a entregar a ANDREANI y EMPRETIENDA
            toda la documentación necesaria que deba presentar o ser requerida
            por cualquier autoridad según la legislación vigente en la materia.
            En virtud de ello, el emprendedor será el único responsable por la
            falta de entrega de dicha documentación, y su falta no podrá ser
            invocada para atribuir un incumplimiento a ANDREANI o EMPRETIENDA.
            <br />
            Es responsabilidad tanto del comprador como del emprendedor la
            veracidad de la información requerida por ANDREANI y EMPRETIENDA.
            Los despachos deben cumplimentar con la normativa exigida por
            ANDREANI y las normas que regulan el transporte de cosas. El
            emprendedor autoriza a ANDREANI a inspeccionar los envíos, sin
            previa solicitud, siempre y cuando dicha inspección se deba realizar
            por cuestiones de seguridad o por solicitud de alguna autoridad
            competente o cualquier otra razón justificada.
            <br />
            El emprendedor será absolutamente responsable del medio de
            transporte contratado y eximirá de toda responsabilidad a
            EMPRETIENDA por cualquier reclamo vinculado al servicio de envío. El
            emprendedor será responsable por las averías, daños o destrucción
            que sufran o causen los bienes transportados derivada de los
            defectos propios de los bienes transportados o su embalaje.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            10. RESPONSABILIDAD DEL COMPRADOR
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            El comprador será absolutamente responsable, si por su error
            voluntario o involuntario, dolo o culpa completara errónea o
            falsamente los datos necesarios para realizar la entrega del paquete
            o por no haber persona que pueda recibir el envío o retirarlo en el
            lugar que el comprador hubiera seleccionado.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            11. NOTIFICACIONES Y CANCELACIÓN DE LA CUENTA O ANUNCIO
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            El EMPRENDEDOR puede cancelar la cuenta activa en cualquier momento
            directamente desde su panel administrador.
            <br />
            LA EMPRESA se reserva el derecho de bloquear su acceso al Servicio y
            el de sus EMPRENDEDORES habilitados e interrumpir su uso ante el
            incumplimiento de los presentes términos y condiciones.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            12. INCUMPLIMIENTO E INDEMNIZACIÓN
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            La violación de estos TÉRMINOS y CONDICIONES lo hará responsable de
            indemnizar cualquier daño y perjuicio ocasionado a EMPRETIENDA y/o a
            sus empleados, funcionarios, directores y agentes. Esta
            indemnización se extiende a todos los gastos, pérdidas, o cualquier
            otro daño, directo o indirecto, patrimonial o extrapatrimonial
            ocasionado, incluyendo, pero no limitando a los gastos legales y
            honorarios de abogados.
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
            13. JURISDICCIÓN Y LEGISLACIÓN APLICABLE
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ lineHeight: '1.6' }}
            align="justify"
          >
            Toda controversia derivada del presente contrato o que guarde
            relación con este será resuelta por un árbitros del Tribunal de
            Arbitraje del Colegio de Abogados de Rosario conforme con su
            Reglamento de Arbitraje.
            <br />
          </Typography>
        </Grid>
      </Grid>
    </ModalUI>
  );
};
