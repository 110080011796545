import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Config } from '../other';

const fetchData = async () => {
  const { data } = await axios
    .get(Config.BACKEND_ENDPOINT + '/tienda/datos-facturacion')
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => console.log('/tienda/datos-facturacion', err));

  return data;
};

const useGetBillingData = () => {
  return useQuery({
    refetchOnMount: false,
    queryKey: ['billingData'],
    queryFn: fetchData,
    gcTime: 12 * 60 * 60 * 1000, // 12 hours
    staleTime: 12 * 60 * 60 * 1000, // 12 hours
  });
};

export default useGetBillingData;
