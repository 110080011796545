import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  InputUI,
  ModuleCardTitleUI,
  SelectUI,
} from '../../../../components/common';
import { DocKyc } from './DocKyc';
import { optStates } from './jsonSelect';
import useGetSucursalesEmprepack from '../../../../hooks/useGetSucursalesEmprepack';
import { Validate } from '../../../../other';

export const ShipmentAddressData = ({
  emprepack,
  handleChange,
  kyc_domicilio,
  configurations,
  customerZipcode,
  handleChangeDoc,
  handleChangeSelect,
  handleChangeZipCode,
}) => {
  const {
    data: sucursalesEmprepackData,
    isLoading: isSucursalesEmprepackLoading,
  } = useGetSucursalesEmprepack();
  const [sucursalesOptions, setSucursalesOptions] = useState(null);

  const getFilteredSucursales = (sucursalesList) => {
    return customerZipcode
      ? sucursalesList.filter((sucursal) => {
          return (
            sucursal.office_id === 0 ||
            customerZipcode === sucursal.office_zipcode ||
            Validate.in_array(customerZipcode, sucursal.office_enabled_zipcodes)
          );
        })
      : sucursalesList;
  };

  useEffect(() => {
    if (!isSucursalesEmprepackLoading) {
      const formattedSucursales = Validate.object_array_sort_by(
        sucursalesEmprepackData,
        'office_state'
      ).map((sucursal) => {
        return {
          ...sucursal,
          label: `${sucursal.office_street} ${sucursal.office_street_number}, ${sucursal.office_city}, ${sucursal.office_state}`,
        };
      });

      setSucursalesOptions(getFilteredSucursales(formattedSucursales));
    }
  }, [isSucursalesEmprepackLoading]);

  return (
    <>
      <Grid container spacing={1.5}>
        <ModuleCardTitleUI
          title="Dirección de origen"
          subtitles={[
            'Esta información será utilizada para determinar el origen del envío.',
          ]}
        />
        {sucursalesEmprepackData && (
          <>
            <Grid item xs={12}>
              <InputUI
                handleChange={handleChange}
                input={emprepack.customer_street}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <InputUI
                handleChange={handleChange}
                input={emprepack.customer_street_number}
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <InputUI
                handleChange={handleChange}
                input={emprepack.customer_floor}
              />
            </Grid>
            <Grid item lg={4} xs={6}>
              <InputUI
                handleChange={handleChange}
                input={emprepack.customer_apartment}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <InputUI
                handleChange={handleChange}
                input={emprepack.customer_city}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <SelectUI
                data={emprepack.customer_state}
                options={optStates}
                handleChangeSelect={handleChangeSelect}
              />
            </Grid>
            <Grid item xs={12}>
              <InputUI
                handleChange={handleChangeZipCode}
                input={emprepack.customer_zipcode}
              />
            </Grid>
            <Grid item xs={12}>
              {sucursalesOptions &&
                sucursalesEmprepackData &&
                configurations && (
                  <SelectUI
                    value_atribute="office_id"
                    options={[
                      {
                        office_id: 0,
                        label: 'Selecciona una sucursal',
                      },
                      ...sucursalesOptions,
                    ]}
                    handleChangeSelect={handleChangeSelect}
                    data={configurations?.me_emprepack_office_id}
                  />
                )}
            </Grid>
            <Grid item xs={12}>
              <DocKyc
                handleChangeDoc={handleChangeDoc}
                input={kyc_domicilio.comprobante_domicilio}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
