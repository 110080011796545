import React from 'react';
import { Validate } from '../../../../other';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {
  CardModuleUI,
  InputUI,
  ModuleCardTitleUI,
} from '../../../../components/common';

export const ShipmentWithChargeData = ({ configurations, handleChange }) => {
  let ejemplo = 500;
  let porcentaje = Validate.trim_float(
    configurations.me_emprepack_recargo_porcentaje.value
  );
  let porcentaje_a_ejemplo = Validate.trim_float(
    Validate.get_fixed_percent(ejemplo, porcentaje)
  );
  let monto = Validate.trim_float(
    configurations.me_emprepack_recargo_monto.value
  );
  let total = ejemplo + porcentaje_a_ejemplo + monto;
  total = total > 0 ? total : 0;
  let porcentaje_label = porcentaje >= 0 ? 'recargo' : 'descuento';
  let monto_fijo_label = monto >= 0 ? 'recargo' : 'descuento';

  return (
    <CardModuleUI below>
      <Grid container spacing={3}>
        <ModuleCardTitleUI
          title="Recargos y/o descuentos (opcional)"
          subtitles={[
            'Podés configurar un aumento o disminución en monto fijo y/o porcentaje al monto tarifado por EmprePack. Por ejemplo, podés agregar el porcentaje de comisión por venta de los métodos de pago, costos de embalaje, despacho, etc o hacer un descuento por una parte del envío a tu cliente y vos abonar esa diferencia.',
            'Para descontar sólo tenés que poner el signo menos (-) delante del monto y/o porcentaje.',
          ]}
        />
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={configurations.me_emprepack_recargo_porcentaje}
            end_adornment="%"
            step="0.01"
          />
        </Grid>
        <Grid item xs={12}>
          <InputUI
            handleChange={handleChange}
            input={configurations.me_emprepack_recargo_monto}
            adornment="$"
            step="0.01"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="body1" mb={1}>
            Ejemplo de cómo tarifaría suponiendo que el costo del envío sea de $
            {Validate.number_format(ejemplo)} (monto no real, los costos de
            envío pueden variar según origen, destino, dimensiones y peso del
            paquete):
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ paddingLeft: '0px' }}>
                  Ejemplo tarifa final Emprepack
                </TableCell>
                <TableCell align="right">
                  ${Validate.number_format(ejemplo)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ paddingLeft: '0px' }}>
                  Porcentaje de {porcentaje_label} ({porcentaje}%)
                </TableCell>
                <TableCell align="right">
                  ${Validate.number_format(porcentaje_a_ejemplo)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ paddingLeft: '0px' }}>
                  Monto de {monto_fijo_label}
                </TableCell>
                <TableCell align="right">
                  ${Validate.number_format(monto)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ paddingLeft: '0px' }}>
                  Tarifa final a pagar por el cliente
                </TableCell>
                <TableCell align="right">
                  ${Validate.number_format(total)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </CardModuleUI>
  );
};
