import React from 'react';
import {
  ButtonUI,
  DropdownUI,
  MenuItemUI,
} from '../../../../components/common';

export const ConfigurationMenu = ({ customer_status, modalHandler }) => {
  if (customer_status >= 0) {
    return (
      <DropdownUI label="Configuración">
        <MenuItemUI
          type="callf"
          onClickFunction={(e) => modalHandler(true, 'editar_domicilio')}
        >
          Editar dirección de origen
        </MenuItemUI>
        <MenuItemUI
          type="callf"
          onClickFunction={(e) => modalHandler(true, 'editar_contacto')}
        >
          Editar información de contacto
        </MenuItemUI>
      </DropdownUI>
    );
  } else {
    return (
      <ButtonUI
        type="link"
        label="Tutorial"
        link="https://recursos.empretienda.com/emprepack.pdf"
        fullWidth={false}
        external_link
        open_new_tab
      />
    );
  }
};
