import axios from 'axios';
import Reducers from '../reducers';
import { Config, Validate } from '../../other';
import { adminLogout } from '.';

//TODO: reemplazar la action, donde se utilice, por el hook useGetShipmentMethods
const metodosenvioObtener = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/medios-envio')
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodosenvioSolicitarEmprepack = (data, callback) => {
  let fdata = new FormData();

  Object.keys(data).forEach((key) => {
    if (
      Validate.in_array(key, [
        'dni_frontal',
        'dni_reverso',
        'dni_selfie',
        'comprobante_domicilio',
      ])
    ) {
      fdata.append('verification_files[]', data[key]);
    } else {
      fdata.append(key, data[key]);
    }
  });

  return (dispatch, _getState) => {
    axios
      .post(
        Config.BACKEND_ENDPOINT + '/medios-envio/emprepack/solicitar',
        fdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodosenvioEditarDireccionEmprepack = (data, callback) => {
  let fdata = new FormData();

  Object.keys(data).forEach((key) => {
    if (Validate.in_array(key, ['comprobante_domicilio'])) {
      fdata.append('verification_files[]', data[key]);
    } else {
      fdata.append(key, data[key]);
    }
  });

  return (dispatch, _getState) => {
    axios
      .post(
        Config.BACKEND_ENDPOINT + '/medios-envio/emprepack/direccion',
        fdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodosenvioEditarContactoEmprepack = (data, callback) => {
  let fdata = new FormData();

  Object.keys(data).forEach((key) => {
    if (Validate.in_array(key, ['dni_frontal', 'dni_reverso', 'dni_selfie'])) {
      fdata.append('verification_files[]', data[key]);
    } else {
      fdata.append(key, data[key]);
    }
  });

  return (dispatch, _getState) => {
    axios
      .post(
        Config.BACKEND_ENDPOINT + '/medios-envio/emprepack/contacto',
        fdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioConfigurarEmprepack = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-envio/emprepack', data)
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioActivarAcordar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-envio/activar-acordar')
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioDesactivarAcordar = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-envio/desactivar-acordar')
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioConfigurarMercadoEnvios = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/medios-envio/configurar-mercadoenvios',
        data
      )
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioConfigurarOca = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-envio/configurar-oca', data)
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioSolicitarCuentaOca = (data, callback) => {
  let fdata = new FormData();
  fdata.append('sc_razon_social', data.sc_razon_social);
  fdata.append('sc_telefono', data.sc_telefono);
  fdata.append('sc_doc_alta_oca', data.sc_doc_alta_oca);
  fdata.append('sc_doc_situacion_fiscal', data.sc_doc_situacion_fiscal);
  fdata.append('sc_doc_constancia_iibb', data.sc_doc_constancia_iibb);
  fdata.append('sc_doc_constancia_afip', data.sc_doc_constancia_afip);

  return (dispatch, _getState) => {
    axios
      .post(
        Config.BACKEND_ENDPOINT + '/medios-envio/oca/solicitar-cuenta',
        fdata,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioObtenerLocalidadesOca = (me_oca_provincia_id, callback) => {
  return (dispatch, _getState) => {
    axios
      .get(
        `${Config.BACKEND_ENDPOINT}/medios-envio/localidades-oca/${me_oca_provincia_id}`
      )
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioObtenerSucursalesAdmisionOca = (callback) => {
  return (dispatch, _getState) => {
    axios
      .get(Config.BACKEND_ENDPOINT + '/medios-envio/sucursales-admision-oca')
      .then((resp) => {
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioConfigurarCorreoArgentino = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/medios-envio/configurar-correoargentino',
        data
      )
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioConfigurarEPick = (data, callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-envio/configurar-epick', data)
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioActivarRetiroLocal = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-envio/activar-local')
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioDesactivarRetiroLocal = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(Config.BACKEND_ENDPOINT + '/medios-envio/desactivar-local')
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioActivarEnvioPersonalizado = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/medios-envio/activar-envio-personalizado'
      )
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

const metodoenvioDesactivarEnvioPersonalizado = (callback) => {
  return (dispatch, _getState) => {
    axios
      .put(
        Config.BACKEND_ENDPOINT + '/medios-envio/desactivar-envio-personalizado'
      )
      .then((resp) => {
        //guardo en redux
        const metodos_envio = resp.data.data;
        dispatch({
          type: Reducers.METODOSENVIO_GUARDAR,
          data: metodos_envio,
        });
        return callback(false, resp.data);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(adminLogout());
        }
        return callback(true, Validate.procesar_error_axios(err.response));
      });
  };
};

export {
  metodosenvioObtener,
  metodosenvioSolicitarEmprepack,
  metodosenvioEditarDireccionEmprepack,
  metodosenvioEditarContactoEmprepack,
  metodoenvioConfigurarEmprepack,
  metodoenvioActivarAcordar,
  metodoenvioDesactivarAcordar,
  metodoenvioConfigurarMercadoEnvios,
  metodoenvioConfigurarOca,
  metodoenvioSolicitarCuentaOca,
  metodoenvioObtenerLocalidadesOca,
  metodoenvioObtenerSucursalesAdmisionOca,
  metodoenvioConfigurarCorreoArgentino,
  metodoenvioConfigurarEPick,
  metodoenvioActivarRetiroLocal,
  metodoenvioDesactivarRetiroLocal,
  metodoenvioActivarEnvioPersonalizado,
  metodoenvioDesactivarEnvioPersonalizado,
};
