import React, { Fragment } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemSecondaryAction,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Validate, Config } from '../../../../../other';
import { useSelector } from 'react-redux';

const ListItemProducto = ({ producto, classes, idCountry }) => {
  let imagen = Config.CLOUDFRONT_PRODUCTS_CDN + producto.img;
  let precio = producto.precio_unitario * producto.cantidad;
  let label = producto.cantidad + ' x ' + producto.nombre;

  //atributos
  let atributos_html = [];
  if (producto.atributos) {
    for (let j in producto.info_atributos) {
      atributos_html = [
        ...atributos_html,
        producto.info_atributos[j].at_nombre +
          ': ' +
          producto.info_atributos[j].vat_valor,
      ];
    }
  }

  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar src={imagen} alt="Imagen producto" />
      </ListItemAvatar>
      <ListItemText
        primary={label}
        secondary={
          <Fragment>
            {atributos_html.map((atributo, idx) => (
              <span className={classes.item_atributo} key={idx}>
                {atributo}
              </span>
            ))}
            <span className={classes.item_atributo}>
              SKU: {producto.sku ? producto.sku : '-'}
            </span>
          </Fragment>
        }
      />
      <ListItemSecondaryAction>
        <Typography>${Validate.number_format(precio, idCountry)}</Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const ListItemDetail = ({ detail }) => {
  let strong = detail.label === 'Total' ? true : false;
  let label_html = strong ? <strong>{detail.label}</strong> : detail.label;
  let monto_html = strong ? <strong>{detail.monto}</strong> : detail.monto;

  return (
    <ListItem divider={detail.divider}>
      <ListItemText primary={label_html} />
      <ListItemSecondaryAction>
        <Typography>{monto_html}</Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const parse_productos = (v_arr_carrito_json) => {
  let productos_prev = JSON.parse(v_arr_carrito_json);

  let productos = [];

  for (let idStock in productos_prev) {
    productos.push(productos_prev[idStock]);
  }

  return productos;
};

const cantidad_productos = (productos) => {
  let cantidad = 0;
  productos.forEach((producto) => {
    cantidad += producto.cantidad;
  });
  return cantidad;
};

const armar_details_arr = (
  venta,
  label_cupon,
  cantidad_productos,
  idCountry
) => {
  let details = [];

  //subtotal
  details.push({
    label:
      'Subtotal (' +
      (cantidad_productos === 1
        ? '1 producto'
        : cantidad_productos + ' productos') +
      ')',
    divider: true,
    monto: '$' + Validate.number_format(venta.v_monto_bruto, idCountry),
  });

  //cupon de descuento
  if (venta.v_cupon > 0) {
    let v_cupon_monto =
      venta.v_cupon_porcentaje > 0
        ? Validate.get_fixed_percent(
            venta.v_monto_bruto,
            venta.v_cupon_porcentaje
          )
        : venta.v_cupon_monto;

    details.push({
      label: 'Descuento (' + label_cupon + ')',
      divider: true,
      monto: '-$' + Validate.number_format(v_cupon_monto, idCountry),
    });
  }

  //reviso si hay un cupon por metodo de pago
  if (venta.v_metodo_pago_descuento) {
    let metodo_pago_descuento_label =
      venta.v_metodo_pago === 2
        ? 'Descuento (pago en efectivo)'
        : 'Descuento (pago con transferencia/depósito)';

    details.push({
      label: metodo_pago_descuento_label,
      divider: true,
      monto:
        '-$' +
        Validate.number_format(venta.v_metodo_pago_descuento_monto, idCountry),
    });
  }

  let v_monto = venta.v_monto * 1;
  let v_arr_envio_seleccionado = JSON.parse(
    venta.v_arr_envio_seleccionado_json
  );

  //costo envio
  switch (v_arr_envio_seleccionado.tipo) {
    //local
    case 2:
      break;
    //acordar
    case 3:
      break;
    //email
    case 7:
      break;

    //personalizados
    case 5:
      switch (v_arr_envio_seleccionado.cobro_envio) {
        //gratis
        case 0:
          details.push({
            label: 'Envío',
            divider: true,
            monto: '$0,00',
          });
          break;
        //se cobra
        case 1:
          details.push({
            label: 'Envío',
            divider: true,
            monto:
              '$' +
              Validate.number_format(
                v_arr_envio_seleccionado.precio,
                idCountry
              ),
          });
          v_monto += v_arr_envio_seleccionado.precio;
          break;
        default:
          break;
      }
      break;

    //oca, mercadoenvios, emprepack, correo argentino, epick
    default:
      if (v_arr_envio_seleccionado.precio > 0) {
        details.push({
          label: 'Envío',
          divider: true,
          monto:
            '$' +
            Validate.number_format(v_arr_envio_seleccionado.precio, idCountry),
        });
        v_monto += v_arr_envio_seleccionado.precio;
      } else {
        details.push({
          label: 'Envío',
          divider: true,
          monto: '$0,00',
        });
      }
      break;
  }

  //total
  details.push({
    label: 'Total',
    divider: false,
    monto: '$' + Validate.number_format(v_monto, idCountry),
  });

  return details;
};

const OrdenDetalle_ = (props) => {
  let { venta, label_cupon, classes } = props;
  const idCountry = useSelector((state) => state.tienda.Country_idCountry);

  let productos = parse_productos(venta.v_arr_carrito_json);
  let details = armar_details_arr(
    venta,
    label_cupon,
    cantidad_productos(productos),
    idCountry
  );

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Detalle de la orden"
        className={classes.card_header}
        classes={{ title: classes.card_title }}
      />
      <Divider />
      <CardContent className={classes.card_content}>
        <List classes={{ root: classes.list }}>
          {productos.map((producto, idx) => (
            <ListItemProducto
              producto={producto}
              classes={classes}
              idCountry={idCountry}
              key={idx}
            />
          ))}
          {details.map((detail, idx) => (
            <ListItemDetail detail={detail} key={idx + 100} />
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

const styles = (theme) => ({
  card: theme.card.card,
  card_header: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  card_content: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  card_title: {
    fontWeight: '500',
    fontSize: '1.1rem',
  },
  list: {
    padding: 0,
  },
  item_atributo: {
    display: 'block',
  },
});

export const OrdenDetalle = withStyles(styles, { withTheme: true })(
  OrdenDetalle_
);
