import React from 'react';
import {
  CardModuleUI,
  CheckboxUI,
  ModuleCardTitleUI,
} from '../../../../components/common';
import { Grid } from '@mui/material';

export default function TyCSection({
  modalHandler,
  handleChangeCheckbox,
  checkboxValue,
}) {
  return (
    <CardModuleUI below>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <ModuleCardTitleUI
            title="Términos y condiciones para activación y uso de Emprepack"
            subtitles={[
              <span>
                Al activar Emprepack declaro que acepto los{' '}
                <u>
                  <a
                    href="#!"
                    style={{
                      color: 'inherit',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      modalHandler(true, 'tos');
                    }}
                  >
                    términos y condiciones
                  </a>
                </u>{' '}
                del servicio y he leído el{' '}
                <u>
                  <a
                    href="https://recursos.empretienda.com/emprepack.pdf"
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      color: 'inherit',
                    }}
                  >
                    documento
                  </a>
                </u>{' '}
                del mismo.
              </span>,
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxUI
            handleChangeCheckbox={handleChangeCheckbox}
            checkbox={checkboxValue}
          />
        </Grid>
      </Grid>
    </CardModuleUI>
  );
}
