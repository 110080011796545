import React from 'react';
import { ModalUI } from '../../../../components/common';
import { ShipmentContactData } from './ShipmentContactData';

export const ModalContactData = ({
  emprepack,
  handleSubmit,
  modalHandler,
  modal_state,
  form_state,
  kyc_dni,
  handleChange,
  handleChangeDoc,
}) => {
  return (
    <ModalUI
      open={modal_state}
      id="editar_contacto"
      title="Editar contacto"
      subtitle="Tene en cuenta que al editar la información de contacto, se reiniciará el proceso de alta con una demora de 72hs. En ese tiempo su tienda no podrá utilizar Emprepack."
      modalHandler={modalHandler}
      aditional_param="editar_contacto"
      handleSubmit={handleSubmit}
      button_label="Editar"
      button_loading={form_state}
    >
      <ShipmentContactData
        emprepack={emprepack}
        kyc_dni={kyc_dni}
        handleChange={handleChange}
        handleChangeDoc={handleChangeDoc}
      />
    </ModalUI>
  );
};
